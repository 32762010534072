<template>
  <button type="button" class="btn text-white" href="#" id="projectSelection" role="button"
          data-bs-toggle="dropdown" aria-expanded="false" style="box-shadow: none;">
    <i class="bi bi-inbox" style="font-style:normal;color: cornflowerblue"
       v-if="this.currentSelected==='entry'"> Eingang</i>
    <i class="bi bi-inbox" style="font-style:normal;"
       v-if="this.currentSelected===''"> Project</i>
    <i class="bi bi-journal-check" style="font-style:normal"
       v-if="this.currentSelected!=='' && this.currentSelected!=='entry'"> {{ this.currentSelected }}</i>
  </button>
  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="projectSelection"
      style="background-color: #2c3e50">
    <input id="searchProject" class="form-control border-0 shadow-none"
           v-model="projectSearch"
           placeholder="Search ..."/>
    <li v-for="project in this.getProjects" :key="project">
      <button class="dropdown-item" v-on:click="$emit('selectedProject','entry')" v-if="project==='entry'">
        <i class="bi bi-inbox" style="color: cornflowerblue"></i> Eingang
      </button>
      <button class="dropdown-item" v-on:click="$emit('selectedProject',project)" v-else>
        <i class="bi bi-journal-check"></i>{{ project }}
      </button>
    </li>
    <li v-if="projectSearch !== ''">
      <button class="dropdown-item" v-on:click="createAndAddProject(projectSearch)" style="font-weight: bold">
        <i class="bi bi-plus-circle"></i> Create: "{{ projectSearch }}"
      </button>
    </li>
  </ul>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "ProjectSelection",
  emits: ["selectedProject"],
  props: {
    currentSelected: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      projectSearch: ''
    }
  },
  computed: {
    ...mapGetters(["getProjects"]),
    getFilteredProjects() {
      return this.getProjects.filter(project => {
        return project.toLowerCase().includes(this.project.toLowerCase())
      })
    }
  },
  methods: {
    ...mapMutations(["addProject"]),

    createAndAddProject(project) {
      this.addProject(project)
      this.$emit('selectedProject', project);
      this.projectSearch = '';

    }
  }
}
</script>

<style scoped>
#searchProject {
  background-color: #2c3e50;
  border-color: #2c3e50;
  color: white;
}

</style>
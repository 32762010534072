<template>
  <div class="d-flex flex-column min-vh-100">
    <div class="container-sm">
      <div class="py-5 text-center">
        <h1 class="d-block mx-auto mb-3">Simple TodoApp</h1>
        <Progress/>
        <p>{{ today }}</p>
        <div class="d-flex justify-content-between align-items-center">
          <h6>Open</h6>
          <div class="d-flex flex-row flex-md-nowrap">
            <button type="button" class="btn btn-white" style="box-shadow: none;" @click="showOpenTodoFilter">
              <i class="bi bi-funnel icon"></i>
            </button>
            <input id="filterInput" class="form-control border-0 shadow-none"
                   v-if="filterOpenTodoInput"
                   v-model="searchOpen"
                   placeholder="..."/>
          </div>
        </div>
        <hr class="my-md-2">
        <div class="scrollable">
          <div class="list-group">
            <div v-for="(todo, index) in openTodosFiltered"
                 :key="todo.id"
                 :title="todo.title" class="mb-sm-0">
              <div id="customstyle" class="lh-2 d-flex custom-list-item">
                <div class="p-2 bd-highlight ">
                  <button type="button" class="btn btn-white btn-sm lock" @click=" deletesTodo(index)"
                          style="box-shadow: none;">
                    <i class="bi bi-circle icon-unlock" style="color: red" v-if="todo.priority===1"></i>
                    <i class="bi bi-check-circle icon-lock" style="color: red" v-if="todo.priority===1"></i>
                    <i class="bi bi-circle icon-unlock" style="color: yellow"
                       v-if="todo.priority===2"></i>
                    <i class="bi bi-check-circle icon-lock" style="color: yellow" v-if="todo.priority===2"></i>
                    <i class="bi bi-circle icon-unlock" style="color: cornflowerblue"
                       v-if="todo.priority===3"></i>
                    <i class="bi bi-check-circle icon-lock" style="color: cornflowerblue"
                       v-if="todo.priority===3"></i>
                    <i class="bi bi-circle icon-unlock" style="color: white" v-if="todo.priority===4"></i>
                    <i class="bi bi-check-circle icon-lock" style="color: white" v-if="todo.priority===4"></i>
                  </button>
                </div>
                <div class="p-2 bd-highlight ">
                  <div class="d-flex align-items-start flex-column ">
                    <p class="mb-0">{{ todo.title }}</p>
                    <small id="textwrap">{{ todo.text }}</small>
                    <div class="d-flex align-items-start flex-md-nowrap p-0">
                      <InlineDates :inputDate="todo.dueDate"/>
                      <div class="p-1">
                        <InlineLabels :delete-label="false" :labels="todo.labels"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ms-auto p-2 bd-highlight">
                  <div class="d-flex align-items-start flex-column ">
                    <div class="row">
                      <div class="d-flex justify-content-start flex-md-nowrap p-0">
                        <button class="btn btn-white btn-sm" @click="edit(index,0)" style="box-shadow: none;">
                          <i class="bi bi-pencil icon"></i>
                        </button>
                        <button type="button" class="btn text-white"
                                style="box-shadow: none;"><i class="bi bi-calendar2-plus"></i>
                        </button>
                        <button type="button" class="btn text-white"
                                style="box-shadow: none;"><i class="bi bi-chat-left"></i>
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex justify-content-start flex-md-nowrap ">
                        <p class="project-label-default" v-if="todo.project==='entry'">
                          <i class="bi bi-inbox">
                          </i>
                          Eingang</p>
                        <p class="project-label" v-if="todo.project!=='entry'">
                          <i class="bi bi-journal-check">
                          </i> {{ todo.project }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-md-0">
            </div>
          </div>
        </div>

        <!--    Add Todo-->
        <AddDialog :inputShowAddDialog="this.showAddDialog" v-on:closeAddDialog="this.showAddDialog=$event"/>
        <!--        TODO: umbauen auf neue flex Struktur-->
        <!-- Done -->
        <div class="d-flex justify-content-between align-items-center mb-3 mt-5">
          <h6>Done</h6>
          <div class="d-flex flex-row flex-md-nowrap">
            <button type="button" class="btn btn-white" @click="showDoneTodoFilter" style="box-shadow: none;">
              <i class="bi bi-funnel icon" style="box-shadow: none;"></i>
            </button>
            <input id="filterInput" class="form-control border-0 shadow-none" v-if="filterDoneTodoInput"
                   v-model="searchDone" placeholder="..."/>
          </div>
        </div>
        <hr class="my-md-2">
        <div class="scrollable">
          <div class="list-group">
            <div class="mb-sm-0"
                 v-for="(todo, index) in doneTodosFiltered"
                 :key="todo.id"
                 :title="todo.title">
              <div id="doneCustomstyle"
                   class="lh-2 d-flex">
                <div class="p-2 bd-highlight ">
                  <button type="button" class="btn btn-white btn-sm" @click="reopen(index)" style="box-shadow: none;">
                    <i class="bi bi-arrow-clockwise icon"></i>
                  </button>
                </div>
                <div class="p-2 bd-highlight">
                  <div class="d-flex align-items-start flex-column ">
                    <p class="mb-0 done">{{ todo.title }}</p>
                    <small id="textwrap">{{ todo.text }}</small>
                    <InlineLabels :delete-label="false" :labels="todo.labels"/>
                  </div>
                </div>
                <div class="ms-auto p-2 bd-highlight">
                  <button type="button" class="btn btn-white btn-sm" @click="edit(index,1)" style="box-shadow: none;">
                    <i class="bi bi-pencil icon"></i>
                  </button>
                  <button type="button" class="btn btn-white btn-sm" @click="removeOneFromDoneTodo(index)"
                          style="box-shadow: none;">
                    <i class="bi bi-trash icon"></i>
                  </button>
                </div>
              </div>
              <hr class="my-md-0">
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="mt-auto text-center">
      <a class="buymeacoffee" href="https://buymeacoffee.com/jonasthomsen">Buy me a Coffee <i class="bi bi-cup"></i></a>
    </footer>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import Progress from "./views/Progress";
import InlineLabels from "./views/InlineLabels";
import InlineDates from "./views/InlineDates";
import AddDialog from "./AddDialog";

export default {
  name: "TodoList",
  components: {
    AddDialog,
    InlineLabels,
    InlineDates,
    Progress
  },
  data() {
    return {
      today: new Date().toLocaleDateString('de-DE', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}),
      doneTodosCount: 0,
      searchOpen: '',
      searchDone: '',
      filterOpenTodoInput: false,
      filterDoneTodoInput: false,
      showAddDialog: false,
    };
  },
  computed: {
    ...mapGetters(["getDoneTodos", "getOpenTodos", "getOpenTodosCount", "getDoneTodosCount", "getOpenTodoByID", "getDoneTodoByID"]),
    openTodosFiltered() {
      return this.getOpenTodos.filter(todo => {
        return todo.title.toLowerCase().includes(this.searchOpen.toLowerCase())
      })
    },
    doneTodosFiltered() {
      return this.getDoneTodos.filter(todo => {
        return todo.title.toLowerCase().includes(this.searchDone.toLowerCase())
      })
    }

  },
  methods: {
    ...
        mapMutations(["addOpenTodo",
          "addDoneTodo",
          "removeOneFromOpenTodo",
          "removeOneFromDoneTodo",
          "addGlobalLabel",
          "editTodo"]),
    showOpenTodoFilter() {
      this.filterOpenTodoInput = !this.filterOpenTodoInput;
    },
    showDoneTodoFilter() {
      this.filterDoneTodoInput = !this.filterDoneTodoInput;
    },
    deletesTodo(index) {
      let done = this.getOpenTodoByID(index);
      done.open = false;
      done.doneDate = new Date().toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
      this.removeOneFromOpenTodo(index)
      this.addDoneTodo(done)
    },
    reopen(index) {
      let doneTodo = this.getDoneTodoByID(index)
      doneTodo.open = true
      doneTodo.doneDate = ''
      this.addOpenTodo(doneTodo);
      this.removeOneFromDoneTodo(index)
    },
    edit(index, type) {
      if (!this.addItem) {
        console.log(index, type)
        this.editTodo({index, type})
        this.showAddDialog = true;
      }
    },
  }

}</script>
<style>

#customstyle, #doneCustomstyle {
  border-bottom-color: lightslategrey;
  border-left-color: #2c3e50;
  border-right-color: #2c3e50;
  border-top-color: #2c3e50;
  background-color: #2c3e50;
  color: white;
}

.done {
  text-decoration: line-through;
}

.scrollable {
  min-height: 300px;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.icon {
  font-size: 1.1rem;
  color: white;
}

.project-label {
  font-size: small;
  color: lightgray;
}

.project-label-default {
  font-size: small;
  color: cornflowerblue;
}

.btn:focus {
  box-shadow: none;
}

.lock:hover .icon-unlock,
.lock .icon-lock {
  display: none;
  color: white;
}

.lock:hover .icon-lock {
  display: inline;
  color: red;
}

.custom-list-item {
  min-height: 75px;
  max-height: 80px;
}

#textwrap {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: lightslategrey;
}

#filterInput {
  background-color: #2c3e50;
  border-color: #2c3e50;
  color: white;
}

.buymeacoffee {
  text-decoration: none;
  color: white;
}

</style>
<template>
  <div>
    <div v-if="deleteLabel" class="d-flex flex-row flex-md-nowrap">
      <button class="btn text-white text-decoration p-0"
              style="box-shadow: none;"
              v-for="label in labels" :key="label"
              v-on:click="$emit('labelToRemove',label)">
        <p class="hashtag">
          <i class="bi bi-tag"></i>{{ label }}
        </p>
      </button>
    </div>
    <div class="d-flex flex-row flex-md-nowrap" v-if="!deleteLabel">
      <p class="hashtag" v-for="label in labels" :key="label">
        <i class="bi bi-tag"></i>{{ label }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InlineLabels',
  data() {
    return {}
  },
  props: {
    deleteLabel: Boolean,
    labels: {}
  },
  methods: {}
}
</script>
<style>


.hashtag {
  font-size: small;
  color: lightgray;
}


</style>
<template>
  <div v-if="inputDate === ''">
  </div>
  <div
      v-else-if="this.isToday(inputDate)">
    <small>
      <i class="bi bi-calendar-date" style="color: green"></i> Today
    </small>
  </div>
  <div v-else-if="this.isTomorrow(inputDate)">
    <small>
      <i class="bi bi-brightness-high-fill" style="color: darkgoldenrod"></i> Tomorrow
    </small>
  </div>
  <div
      v-else-if="this.isNextMonday(inputDate)">
    <small>
      <i class="bi bi-calendar-minus-fill" style="color: purple"></i> Monday
    </small>
  </div>
  <div v-else-if="this.isWeekendAndNotTomorrow(inputDate)">
    <small>
      <i class="bi bi-tsunami" style="color: cornflowerblue"></i> Weekend
    </small>
  </div>
  <div v-else>
    <small>
      <i class="bi bi-calendar-check" style="color: cornflowerblue"></i>
      {{ inputDate.toLocaleString('de-DE', {weekday: 'short', day: 'numeric', month: 'numeric'}) }}
    </small>
  </div>
</template>

<script>

export default {
  name: "InlineDates",
  props: {
    inputDate: Date,
    isButton: Boolean,
  },
  methods: {
    getToday() {
      return new Date();
    }
    ,
    getTomorrow() {
      let today = new Date();
      return new Date(today.getTime() + 24 * 60 * 60 * 1000);
    }
    ,
    getNextMonday() {
      let d = new Date();
      d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));
      return d;
    }
    ,
    getNextSaturday() {
      let d = new Date();
      d.setDate(d.getDate() + (((1 + 5 - d.getDay()) % 5) || 5));
      // return d.toLocaleString('de-DE', {weekday: 'short'});
      return d;
    }
    ,
    getTodayLabel() {
      return this.getToday().toLocaleString('de-DE', {weekday: 'short'});
    }
    ,
    getTomorrowLabel() {
      return this.getTomorrow().toLocaleString('de-DE', {weekday: 'short'});

    }
    ,
    getNextMondayLabel() {
      return this.getNextMonday().toLocaleString('de-DE', {weekday: 'short', month: 'short', day: 'numeric'});

    }
    ,
    getNextSaturdayLabel() {
      return this.getNextSaturday().toLocaleString('de-DE', {weekday: 'short'});
    }
    ,
    isToday(date) {
      return date.toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }) === this.getToday().toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
    }
    ,
    isTomorrow(date) {
      return date.toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }) === this.getTomorrow().toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
    }
    ,
    isNextMonday(date) {
      return date.toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }) === this.getNextMonday().toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
    }
    ,
    isWeekendAndNotTomorrow(date) {
      return (date.toLocaleString('de-DE', {
            weekday: 'short', day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }) === this.getNextSaturday().toLocaleString('de-DE', {
            weekday: 'short', day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }))
          && (this.getNextSaturday().toLocaleString('de-DE', {
            weekday: 'short', day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }) !== this.getTomorrow().toLocaleString('de-DE', {
            weekday: 'short', day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }))
    }
    ,
    isSaturdayNotTomorrow() {
      return (this.getNextSaturday().toLocaleString('de-DE', {
        weekday: 'short', day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }) !== this.getTomorrow().toLocaleString('de-DE', {
        weekday: 'short', day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }))
    }
    ,
  },
}
</script>

<style scoped>

</style>
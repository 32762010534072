<template>
  <button type="button" class="btn text-white" id="navbarDarkDropdownMenuLink" role="button"
          data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"
          style="box-shadow: none;">
    <div v-if="inputDate === ''"><i class=" bi bi-calendar2-plus"></i> Plan
    </div>
    <div
        v-else-if="this.isToday(inputDate)">
      <i class="bi bi-calendar-date" style="color: green"></i> Today
    </div>
    <div
        v-else-if="this.isTomorrow(inputDate)">
      <i class="bi bi-brightness-high-fill" style="color: darkgoldenrod"></i> Tomorrow
    </div>
    <div
        v-else-if="this.isNextMonday(inputDate)">
      <i class="bi bi-calendar-minus-fill" style="color: purple"></i> Monday
    </div>
    <div
        v-else-if="this.isWeekendAndNotTomorrow(inputDate)">
      <i class="bi bi-tsunami" style="color: cornflowerblue"></i> Weekend
    </div>
    <div
        v-else-if="this.inputDate !== '' && this.currentSelected === ''">
      <i class="bi bi-calendar-check" style="color: cornflowerblue"></i>
      {{ this.inputDate.toLocaleString('de-DE', {weekday: 'short', day: 'numeric', month: 'numeric'}) }}
    </div>
    <div v-else>
      <i class="bi bi-calendar-check" style="color: cornflowerblue"></i>
      {{ this.currentSelected.toLocaleString('de-DE', {weekday: 'short', day: 'numeric', month: 'numeric'}) }}
    </div>
  </button>
  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink"
      style="background-color: #2c3e50">
    <button class="dropdown-item p-0" v-on:click="selectDate(this.getToday())">
      <div class="lh-2 d-flex list-item">
        <div class="p-2 bd-highlight ">
          <i class="bi bi-calendar-date" style="color: green"></i>
        </div>
        <div class="p-2 bd-highlight ">
          <small> Heute</small>
        </div>
        <div class="ms-auto p-2 bd-highlight">
          <small>{{ this.getTodayLabel() }}.</small>
        </div>
      </div>
    </button>
    <button class="dropdown-item p-0" v-on:click="selectDate(this.getTomorrow())">
      <div class="lh-2 d-flex list-item">
        <div class="p-2 bd-highlight ">
          <i class="bi bi-brightness-high-fill" style="color: darkgoldenrod"></i>
        </div>
        <div class="p-2 bd-highlight ">
          <small>Morgen</small>
        </div>
        <div class="ms-auto p-2 bd-highlight">
          <small>{{ this.getTomorrowLabel() }}.</small>
        </div>
      </div>
    </button>
    <button class="dropdown-item p-0" v-on:click="selectDate(this.getNextSaturday())"
            v-if="false">
      <div class="lh-2 d-flex list-item">
        <div class="p-2 bd-highlight ">
          <i class="bi bi-tsunami" style="color: cornflowerblue"></i>
        </div>
        <div class="p-2 bd-highlight ">
          <small>Dieses Wochenende</small>
        </div>
        <div class="ms-auto p-2 bd-highlight">
          <small>{{ this.getNextSaturdayLabel() }}.</small>
        </div>
      </div>
    </button>
    <button class="dropdown-item p-0" v-on:click="selectDate(this.getNextMonday())">
      <div class="lh-2 d-flex list-item">
        <div class="p-2 bd-highlight ">
          <i class="bi bi-calendar-minus-fill" style="color: purple"></i>
        </div>
        <div class="p-2 bd-highlight ">
          <small>Nächste Woche</small>
        </div>
        <div class="ms-auto p-2 bd-highlight">
          <small>{{ this.getNextMondayLabel() }}</small>
        </div>
      </div>
    </button>
    <button class="dropdown-item p-0" v-on:click="selectDate( '')">
      <div class="lh-2 d-flex list-item">
        <div class="p-2 bd-highlight ">
          <i class="bi bi-calendar-minus-fill" style="color: grey"></i>
        </div>
        <div class="p-2 bd-highlight ">
          <small>Kein Datum</small>
        </div>
      </div>
    </button>
    <v-date-picker
        ref="calendar"
        mode="date"
        v-model="currentSelected"
        class='date-picker'
        :attributes='attrs'
        :min-date="new Date()"
        is-dark
        v-on:click="selectDate(currentSelected)"

    />
  </ul>

</template>
<script>

export default {
  name: 'PlannerSelection',
  emits: ["selectedDate"],
  props: {
    inputDate: {
      type: String,
    }
  },
  data() {
    return {

      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: 'green',
            borderRadius: '0'
          },

          contentStyle: {
            borderRadius: '0',
            color: 'red',
          },
        },
      ],
      currentSelected: '',
    };
  },
  methods: {
    selectDate(date) {
      this.currentSelected = date;
      this.$emit('selectedDate', date);
    },
    getToday() {
      return new Date();
    }
    ,
    getTomorrow() {
      let today = new Date();
      return new Date(today.getTime() + 24 * 60 * 60 * 1000);
    }
    ,
    getNextMonday() {
      let d = new Date();
      d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));
      return d;
    }
    ,
    getNextSaturday() {
      let d = new Date();
      d.setDate(d.getDate() + (((1 + 5 - d.getDay()) % 5) || 5));
      // return d.toLocaleString('de-DE', {weekday: 'short'});
      return d;
    }
    ,
    getTodayLabel() {
      return this.getToday().toLocaleString('de-DE', {weekday: 'short'});
    }
    ,
    getTomorrowLabel() {
      return this.getTomorrow().toLocaleString('de-DE', {weekday: 'short'});

    }
    ,
    getNextMondayLabel() {
      return this.getNextMonday().toLocaleString('de-DE', {weekday: 'short', month: 'short', day: 'numeric'});

    }
    ,
    getNextSaturdayLabel() {
      return this.getNextSaturday().toLocaleString('de-DE', {weekday: 'short'});
    }
    ,
    isToday(date) {
      return date.toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }) === this.getToday().toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
    }
    ,
    isTomorrow(date) {
      return date.toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }) === this.getTomorrow().toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
    }
    ,
    isNextMonday(date) {
      return date.toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }) === this.getNextMonday().toLocaleString('de-DE', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
    }
    ,
    isWeekendAndNotTomorrow(date) {
      return (date.toLocaleString('de-DE', {
            weekday: 'short', day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }) === this.getNextSaturday().toLocaleString('de-DE', {
            weekday: 'short', day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }))
          && (this.getNextSaturday().toLocaleString('de-DE', {
            weekday: 'short', day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }) !== this.getTomorrow().toLocaleString('de-DE', {
            weekday: 'short', day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }))
    }
    ,
    isSaturdayNotTomorrow() {
      return (this.getNextSaturday().toLocaleString('de-DE', {
        weekday: 'short', day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }) !== this.getTomorrow().toLocaleString('de-DE', {
        weekday: 'short', day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }))
    }
    ,
  }
}
</script>
<style>

.btn:focus {
  box-shadow: none;
}


.vc-pane-container {
  background-color: #2c3e50;

}

</style>
<template>
  <p>Progress {{ this.getDoneTodosCount }} / {{ this.getDoneTodosCount + this.getOpenTodosCount }}
    <i v-if="this.getDoneTodosCount > 0 && this.getOpenTodosCount === 0" class="bi-check-lg check-icon"></i>
  </p>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'Progress',
  computed: {
    ...mapGetters(["getDoneTodos", "getOpenTodos", "getOpenTodosCount", "getDoneTodosCount", "getOpenTodoByID", "getDoneTodoByID"]),
  }
}
</script>
<style>


.check-icon {
  color: green;
}


</style>
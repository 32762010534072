<template>
  <button type="button" class="btn text-white" href="#" id="navbarDarkDropdownMenuLink" role="button"
          data-bs-toggle="dropdown" aria-expanded="false" style="box-shadow: none;">
    <i class="bi bi-tag"></i>
  </button>
  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink"
      style="background-color: #2c3e50">

    <input id="searchInput" class="form-control border-0 shadow-none"
           v-model="labelSearch"
           placeholder="Search ..."/>
    <li v-for="label in getFilteredLabels" :key="label">
      <button class="dropdown-item" v-on:click="$emit('selectedLabel',label)">
        <i class="bi bi-tag"></i> {{ label }}
      </button>
    </li>
    <li v-if="labelSearch !== ''">
      <button class="dropdown-item" v-on:click="createAndAddLabel(labelSearch)" style="font-weight: bold">
        <i class="bi bi-plus-circle"></i> Create: {{ labelSearch }}
      </button>
    </li>
  </ul>


</template>
<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'LabelSelection',
  emits: ["selectedLabel"],
  data() {
    return {
      labelSearch: '',
    }
  },

  computed: {
    ...mapGetters(["getGlobalLabels"]),
    getFilteredLabels() {
      let current = Array.from(this.getGlobalLabels);
      return current.filter(label => {
        return label.toLowerCase().includes(this.labelSearch.toLowerCase())
      })

    }
  },
  methods: {
    ...
        mapMutations(["addGlobalLabel"]),

    createAndAddLabel(label) {
      this.addGlobalLabel(label)
      this.$emit('selectedLabel', label);
      this.labelSearch = '';

    }
  }


}
</script>
<style>


.btn:focus {
  box-shadow: none;
}

#searchInput {
  background-color: #2c3e50;
  border-color: #2c3e50;
  color: white;
}

</style>
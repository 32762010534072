<template>
  <TodoList></TodoList>
</template>

<script>


import TodoList from "@/components/TodoList";

export default {
  name: 'App',
  components: {
    TodoList
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #2c3e50;
}


</style>

const state = {
    openTodos: [],
    doneTodos: [],
    labels: new Set(),
    projects: [
        "entry", "Einkaufen", "Arbeit", "Privat"
    ],
    editable: ''
};

const getters = {
        getProjects: state => state.projects,
        getEditable: state => state.editable,
        getGlobalLabels: state => state.labels,
        getOpenTodos: state => state.openTodos,
        getDoneTodos: state => state.doneTodos,
        getOpenTodosCount: state => state.openTodos.length,
        getDoneTodosCount: state => state.doneTodos.length,
        getOpenTodoByID: (state) => (index) => state.openTodos[index],
        getDoneTodoByID: (state) => (index) => state.doneTodos[index],
    }
;

const actions = {};

const mutations = {
    addOpenTodo: (state, todo) => {
        state.openTodos.push(todo);
    },

    addDoneTodo: (state, todo) => {
        state.doneTodos.push(todo);
    },
    addGlobalLabel: (state, label) => {
        state.labels.add(label);
    },
    removeOneFromOpenTodo: (state, index) => state.openTodos.splice(index, 1),
    removeOneFromDoneTodo: (state, index) => state.doneTodos.splice(index, 1),
    addProject: (state, project) => {
        if (!state.projects.includes(project))
            state.projects.push(project)
    },
    editTodo: (state, {index, type}) => {
        if (type === 0) {
            state.editable = state.openTodos[index];
            state.openTodos.splice(index, 1);
        }
        if (type === 1) {
            state.editable = state.doneTodos[index];
            state.doneTodos.splice(index, 1);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}

<template>
  <div>
    <div v-if="addItem || inputShowAddDialog">
      <div class="mt-4 border">
        <input id="todoInput" ref="todoInput" class="form-control shadow-none" placeholder="Titel"
               v-model="newTodoTitel">
        <textarea placeholder="Description" id="todoInput" class="form-control  shadow-none"
                  v-model="newTodoText"></textarea>
        <InlineLabels :delete-label="true" v-bind:labels="selectedLabels"
                      v-on:labelToRemove="this.selectedLabels.delete($event)"></InlineLabels>
        <!-- Add Labeling Menu -->
        <div class="d-flex justify-content-between align-items-center flex-row">
          <div>
            <PlannerSelection v-on:selectedDate="this.selectedDate =$event"
                              v-bind:inputDate="this.selectedDate"/>
            <ProjectSelection v-on:selectedProject="this.selectedProject =$event"
                              v-bind:currentSelected="this.selectedProject"></ProjectSelection>
          </div>
          <div>
            <LabelSelection v-on:selectedLabel="this.selectedLabels.add($event)"/>
            <PrioritySelection v-bind:currentSelected="this.selectedPriority"
                               v-on:selected-priority="this.selectedPriority=$event"/>
          </div>
        </div>
      </div>
      <!-- Add Button Group-->
      <div class="d-flex d-block flex-row">
        <button type="button" class="btn text-white text-decoration-underline" @click="addNewTodo"
                style="box-shadow: none;">Add
        </button>
        <button type="button" class="btn text-white text-decoration-underline" @click="closeAddDialog"
                style="box-shadow: none;">Abort
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-start align-items-center p-2" v-if="!addItem && !inputShowAddDialog">
      <label>Add Task</label>
      <button type="button" class="btn btn-white" @click="showAddDialog" style="box-shadow: none;">
        <i class="bi bi-plus-circle icon"></i>
      </button>
    </div>
  </div>
</template>
<script>
import InlineLabels from "./views/InlineLabels"
import LabelSelection from "./Selections/LabelSelection"
import PlannerSelection from "./Selections/PlannerSelection"
import PrioritySelection from "./Selections/PrioritySelection"
import ProjectSelection from "./Selections/ProjectSelection"
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'AddDialog',
  components: {InlineLabels, LabelSelection, PlannerSelection, PrioritySelection, ProjectSelection},
  props: {
    inputShowAddDialog: Boolean,
  },
  data() {
    return {
      newTodoTitel: '',
      newTodoText: '',
      nextTodoId: 1,
      addItem: false,
      filterOpenTodoInput: false,
      filterDoneTodoInput: false,
      selectedPriority: 4,
      selectedLabels: new Set(),
      selectedProject: '',
      selectedDate: ''
    }
  },
  computed: {
    ...mapGetters(["getEditable", "getProjects"]),
  },
  watch: {
    inputShowAddDialog: function () {
      this.fillDialog()
    },
  },
  methods: {
    ...mapMutations(["addOpenTodo",
      "addGlobalLabel"]),
    fillDialog() {
      let editable = this.getEditable;
      this.newTodoText = editable.text;
      this.newTodoTitel = editable.title;
      this.selectedPriority = editable.priority;
      this.selectedLabels = editable.labels;
      this.selectedProject = editable.project;
      this.selectedDate = editable.dueDate;
    },
    closeAddDialog() {
      this.addItem = false
      this.newTodoTitel = ''
      this.newTodoText = ''
      this.selectedLabels = new Set()
      this.selectedPriority = 4;
      this.selectedDate = '';
      this.$emit('closeAddDialog', false);
    },
    showAddDialog() {
      this.addItem = true
    },
    addNewTodo() {
      this.findHashtags(this.newTodoTitel)
      if (this.newTodoTitel.length > 0) {
        this.addOpenTodo({
          id: this.nextTodoId++,
          open: true,
          project: this.selectedProject === '' ? "entry" : this.selectedProject,
          title: this.removeHashtags(this.newTodoTitel),
          labels: this.union(new Set(this.findHashtags(this.newTodoTitel)), this.selectedLabels),
          text: this.newTodoText,
          priority: this.selectedPriority,
          dueDate: this.selectedDate,
          date: new Date().toLocaleDateString('de-DE', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',

          }),
        });
        this.closeAddDialog();
      }
    },
    findHashtags(searchText) {
      let hashtags = searchText.split(' ').filter(v => v.startsWith('#'))
      let tags = [];
      hashtags.forEach(tag => {
        let current = tag.replace('#', '')
        this.addGlobalLabel(current)
        tags.push(current)
      })
      return tags;
    }
    ,
    removeHashtags(input) {
      let regexp = new RegExp('#([^\\s]*)', 'g');
      return input.replace(regexp, "")
    }
    ,
    union(setA, setB) {
      const union = new Set(setA);
      for (const elem of setB) {
        union.add(elem);
      }
      return union;
    }
  },
}
</script>
<style>

#todoInput {
  background-color: #2c3e50;
  border-color: #2c3e50;
  color: white;
}

.icon {
  font-size: 1.1rem;
  color: white;
}

.btn:focus {
  box-shadow: none;
}

.modal-backdrop {
  background-color: #2c3e50;
}

</style>
<template>
  <button class="btn text-white" href="#" id="navbarDarkDropdownMenuLink" role="button"
          data-bs-toggle="dropdown" aria-expanded="false" style="box-shadow: none;">
    <i class="bi bi-flag-fill" style="color: red" v-if="currentSelected===1"></i>
    <i class="bi bi-flag-fill" style="color: yellow" v-if="currentSelected===2"></i>
    <i class="bi bi-flag-fill" style="color: cornflowerblue" v-if="currentSelected===3"></i>
    <i class="bi bi-flag" style="color: white" v-if="currentSelected===4"></i>
  </button>
  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink"
      style="background-color: #2c3e50">
    <li>
      <div class=" d-flex justify-content-end lh-sm">

        <button class="dropdown-item" v-on:click="$emit('selectedPriority',1)">
          <i class="bi bi-flag-fill" style="color: red"></i> Priority 1
        </button>
      </div>
    </li>
    <li>
      <div>
        <button class="dropdown-item" v-on:click="$emit('selectedPriority',2)">
          <i class="bi bi-flag-fill" style="color: yellow"></i> Priority 2
        </button>
      </div>
    </li>
    <li>
      <div>
        <button class="dropdown-item" v-on:click="$emit('selectedPriority',3)">
          <i class="bi bi-flag-fill" style="color: cornflowerblue"></i> Priority 3
        </button>
      </div>
    </li>
    <li>
      <div>
        <button class="dropdown-item" v-on:click="$emit('selectedPriority',4)">
          <i class="bi bi-flag"></i> Priority 4
        </button>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'PrioritySelection',
  emits: ["selectedPriority"],
  props: {
    currentSelected: {
      type: Number,
      default: 4
    }
  }
}
</script>
<style>


.btn:focus {
  box-shadow: none;
}

</style>